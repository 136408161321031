
























































import { Component, Mixins } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { OrgQueryModel } from '@common-src/entity-model/org-entity';
import { OrgDepartmentEntityModel, OrgDepartmentQueryModel } from '@common-src/entity-model/org-department-entity';
import OrgDepartmentService from '@common-src/service/org-department';
import { OrgTreeType } from '@common-src/model/enum';
import { message } from 'ant-design-vue';

@Component
export default class OrgDepartmentListComponent extends Mixins(TableDialogFormComponent) {
    OrgDepartmentEntityModel = OrgDepartmentEntityModel;
    OrgTreeType = OrgTreeType;
    created() {
        this.initTable({
            service: OrgDepartmentService,
            queryModel: new OrgDepartmentQueryModel(),
            tableColumns: OrgDepartmentEntityModel.getTableColumns()
        });
        this.getList();
    }

    addClickHandler(record: any) {
        const model = new OrgDepartmentEntityModel();
        model.parentId = record.id;
        this.addClick(model);
    }

    batchImport() {
        this.$emit('import');
    }

    deleteClickHandler(model: any) {
        this.$confirm({
            title: `组织删除`,
            content: `请确认是否将组织永久删除`,
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                this.deleteClick(model).then(res => {
                    this.getList();
                });
            }
        });
    }

    moveClick(record: any, type: string) {
        OrgDepartmentService.moveOrg({ id: record.id, moveType: type }).then((res) => {
            message.success('移动成功');
            this.getList();
        });
    }

    refreshTree() {
        this.getList();
    }
}

