





































import { Component, Mixins } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { OrgQueryModel } from '@common-src/entity-model/org-entity';
import { UserEntityModel, UserResetPwdModel } from '@common-src/entity-model/user-entity';
import OrgService from '@common-src/service/org';
import UserService, { UserResetPwdService } from '@common-src/service/user-v2';
import { ViewModeType, OrganizationType } from '@common-src/model/enum';
import { UserStoreModule } from '@common-src/store/modules/user';
import UserPwdComponent from '../user/user-pwd.vue';
import OrgDepartmentListComponent from './org-department-list.vue';
import UserOrgBatchImportListComponent from '../user/user-org-batch-import.vue';

@Component({
    components: {
        'user-pwd-dialog': UserPwdComponent,
        'org-department-list-component': OrgDepartmentListComponent,
        'user-org-batch-import-list-component': UserOrgBatchImportListComponent
    }
})
export default class OrgListComponent extends TableDialogFormComponent<any, any> {
    UserEntityModel = UserEntityModel;
    OrganizationType = OrganizationType;
    orgHeadIds = [];
    tabsActiveKey: any = OrganizationType.DEPARTMENT;

    isBatchImport = false;

    created() {
        this.initTable({
            service: new UserService(),
            queryModel: new OrgQueryModel(),
            tableColumns: _.filter(UserEntityModel.getTableColumns(), item => {
                if (this.IsCorp) {
                    return item.dataIndex !== 'projectName' && item.dataIndex !== 'orgName';
                }
                return item.dataIndex !== 'projectName';
            })
        });
        this.tabsActiveKey = OrganizationType.DEPARTMENT;
    }

    treeSelect(groupIds: Array<string>) {
        const groupId = _.first(groupIds);
        (this.queryModel as OrgQueryModel).groupIds = groupIds;
        if (groupId) {
            this.$router.push(`${this.$route.path}?groupId=${groupId}`);
            OrgService.retrieve(groupId).then((res:any) => {
                this.orgHeadIds = res.userIds;
            });
        } else {
            this.$router.push(this.$route.path);
        }
    }

    get PageName() {
        return '组织架构';
    }

    /**
     * 是否企业用户
     */
    get IsCorp() {
        return !UserStoreModule.CurrentProjectId;
    }

    /**
     * 删除操作
     * @param model 数据模型
     */
    async onDeleteClick(record:any): Promise<boolean> {
        if (!record.orgIds.length) {
            this.showMessageWarning('该用户还没有分配组织架构');
            return;
        }
        this.startFullScreenLoading('正在保存');
        return OrgService.deleteOrgUsers(record.orgIds[0], record.id).then((res) => {
            this.removeModel(record);
            this.showMessageSuccess('移出职能部门成功');
            return true;
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }

    resetPwdClick(userModel: UserEntityModel) {
        const userPwdModel = new UserResetPwdModel();
        userPwdModel.id = userModel.id;
        (this.$refs.userPwdFormDialog as any).dialogOpen(userPwdModel, new UserResetPwdService(), ViewModeType.UPDATE);
        (this.$refs.userPwdFormDialog as any).dialogTitle = '重置密码';
    }

    batchImport() {
        this.isBatchImport = true;
    }

    refreshOrgTree() {
        this.isBatchImport = false;
        (this.$refs.orgDepartmentListComponent as OrgDepartmentListComponent)?.refreshTree();
    }
}

