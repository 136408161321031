var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-component" },
    [
      _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "flex justify-between m-b-16" },
            [
              _c("a-input-search", {
                staticStyle: { width: "400px" },
                attrs: { placeholder: "搜索部门名称" },
                on: { search: _vm.searchClick },
                model: {
                  value: _vm.queryModel.orgName,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryModel, "orgName", $$v)
                  },
                  expression: "queryModel.orgName",
                },
              }),
              _c(
                "div",
                [
                  _c(
                    "jtl-button",
                    { staticClass: "m-r-16", on: { click: _vm.batchImport } },
                    [_vm._v("批量导入/导出")]
                  ),
                  _c(
                    "jtl-button",
                    {
                      attrs: { type: "primary mr-r16" },
                      on: {
                        click: function ($event) {
                          _vm.addClick(new _vm.OrgDepartmentEntityModel())
                        },
                      },
                    },
                    [_vm._v("新建部门")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("a-table", {
            attrs: {
              loading: _vm.listLoading,
              columns: _vm.tableColumns,
              "data-source": _vm.listData,
              pagination: false,
              scroll: _vm.tableScroll,
              "row-key": "id",
              "row-selection": {
                selectedRowKeys: _vm.selectedRowKeys,
                onChange: _vm.onSelectChange,
              },
              size: _vm.TableSize,
            },
            on: { change: _vm.tableChange },
            scopedSlots: _vm._u([
              {
                key: "leaders",
                fn: function (text) {
                  return [
                    text && text.length > 0
                      ? _vm._l(text, function (item, index) {
                          return _c(
                            "a-tag",
                            {
                              key: index,
                              staticClass: "m-2",
                              attrs: { color: "blue" },
                            },
                            [_vm._v(" " + _vm._s(item) + " ")]
                          )
                        })
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "updateUserName",
                fn: function (text) {
                  return [
                    text
                      ? _c(
                          "a-tag",
                          { staticClass: "m-2", attrs: { color: "blue" } },
                          [_vm._v(_vm._s(text))]
                        )
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "action",
                fn: function (text, record) {
                  return [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addClickHandler(record)
                          },
                        },
                      },
                      [_vm._v("添加子部门")]
                    ),
                    record.orgType !== _vm.OrgTreeType.CORP
                      ? [
                          _c("a-divider", { attrs: { type: "vertical" } }),
                          _c(
                            "a-dropdown",
                            {
                              staticClass: "tree-item-action-wrapper",
                              attrs: {
                                trigger: ["click"],
                                placement: "bottomRight",
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "tree-item-action-dot",
                                  on: {
                                    click: function (e) {
                                      return e.preventDefault()
                                    },
                                  },
                                },
                                [_c("a-icon", { attrs: { type: "ellipsis" } })],
                                1
                              ),
                              _c(
                                "a-menu",
                                { attrs: { slot: "overlay" }, slot: "overlay" },
                                [
                                  _c("a-menu-item", { key: "0" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "p-12",
                                        on: {
                                          click: function ($event) {
                                            return _vm.moveClick(record, "UP")
                                          },
                                        },
                                      },
                                      [_vm._v("上移")]
                                    ),
                                  ]),
                                  _c("a-menu-item", { key: "1" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "p-12",
                                        on: {
                                          click: function ($event) {
                                            return _vm.moveClick(record, "DOWN")
                                          },
                                        },
                                      },
                                      [_vm._v("下移")]
                                    ),
                                  ]),
                                  _c(
                                    "a-menu-item",
                                    {
                                      key: "2",
                                      on: {
                                        click: function ($event) {
                                          return _vm.editClick(record, true)
                                        },
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "p-12" }, [
                                        _vm._v("编辑"),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "a-menu-item",
                                    {
                                      key: "3",
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteClickHandler(record)
                                        },
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "p-12" }, [
                                        _vm._v("删除"),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("jtl-entity-dialog", {
        ref: "formDialog",
        on: { dialogOK: _vm.formDialogOK },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }